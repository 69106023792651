$shadow: 10px 10px 20px 0px #A6B4C8B2, -12px -12px 20px 0px #FFFFFFCC;
$background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
$primary-color: #2877EE;
$secondary-color: #15274B;
$white: #FFFFFF;
$color-orange: #FF5F00;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #e9edf0;
  font-family: 'Poppins';

  &.logout {
    background-image: url("../images/drug.png");
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}


ul {
  list-style-type: none;
}

.h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
  color: $secondary-color;
}

a {
  text-decoration: none;

}

.login-form {
  width: 590px;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  box-shadow: -12px -12px 20px rgba(166, 180, 200, 0.7);
  border-radius: 20px;
}

.log-in {
  height: 812px;

  .login-form {
    display: flex;
    flex-direction: column;
    height: 520px;
    padding: 37px 102px 43px 102px;
    margin: 90px auto 0 auto;
  }

  h2 {
    padding-bottom: 20px;
  }


  .title-block {
    padding-bottom: 28px;
  }

  .password-text {
    display: flex;
    justify-content: space-between;
  }

  .input-container {
    width: 386px;
  }

  .input {
    margin: 6px 0 24px 0;
  }

  .input-checkbox-block {
    display: flex;
    align-items: center;

  }

  .input-block label {
    color: #00758B;
    font-size: 16px;
    font-weight: 600;
  }

  .input-checkbox {
    width: 24px;
    height: 24px;
    margin-right: 9px;
    background: #00758B;
    box-shadow: 0px 4px 4px rgba(166, 180, 200, 0.7);
    border-radius: 3px;
  }

  .btn-log-in {
    font-weight: 600;
    margin: 31px 0 33px 0;
    min-height: 60px;
  }

  .input-checkbox-block span {
    display: none;
  }
}

#wrapper {
  display: flex;
}

#menu {
  width: 252px;
  height: 100vh;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  border: 0.5px solid;
  border-image-source: $background;
  box-shadow: $shadow;
  padding: 16px 20px;
  position: fixed;
  z-index: 5;

  .logoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 65px;

    img {
      height: 46px;
      width: 55px;
      border-radius: 10px;
      background: $background;
      border: 0.5px solid;
      border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
      box-shadow: $shadow;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: contain;
    }

    .companyName {
      height: 47px;
      width: 111px;
      border-radius: 10px;
      background: $background;
      box-shadow: $shadow;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary-color;
    }

    .border {
      height: 30px;
      width: 1px;
      background: #2877EE;

    }
  }

  .menuItem {
    display: flex;
    height: 51px;
    width: 212px;
    border-radius: 10px;
    border: 0.5px solid;
    background: $background;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    align-items: center;
    padding: 16px 5px 17px 25px;
    margin-bottom: 15px;
    text-decoration: none;
    color: $secondary-color;
    outline: none;

    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }

    svg {
      margin-right: 10px;

      path {
        fill: $secondary-color;
      }
    }

    &.active {
      background: $primary-color;
      color: $white;

      svg {
        path {
          fill: $white;
        }
      }
    }

  }
}

#navBar {
  height: 73px;
  border-radius: 10px;
  border-image-source: $background;
  box-shadow: $shadow;
  background: $background;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  width: calc(100% - 252px);
  z-index: 15;
  left: 252px;

  .iconButton {
    margin-right: 10px;
  }

  .otcSwitcher {
    margin-right: 49px;
  }

  .notificationsContainer {
    position: relative;
    //width: 100vw;
    //height: 100vh;
    //background: red;
  }
}


.container {
  margin-left: 252px;
  position: relative;
  z-index: 10;
  width: calc(100% - 252px);
}

.chatBarOpen {
  .container {
    width: calc(100% - 333px);
    margin-left: 0;
  }

  #navBar {
    left: unset;
    width: calc(100% - 333px);

  }
}

.mainContent {
  margin-top: 73px;
}

.iconButton {
  height: 52px;
  width: 52px;
  border-radius: 40px;
  border-image-source: $background;
  box-shadow: $shadow;
  border: 0.5px solid;
  border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  .count {
    position: absolute;
    top: -4px;
    right: -5px;
    background: #2877EE;
    width: 18px;
    height: 18px;
    border-radius: 40px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
  }
}

#settingsDropdown {
  position: relative;
  margin-left: 11px;

  .user {
    display: flex;
    align-items: center;
    height: 53px;
    box-shadow: $shadow;
    background: $background;
    border-radius: 10px;
    padding: 9px 13px;

    img {
      width: 30px;
      height: 28px;
      margin-right: 6px;
      border-radius: 8px;
    }

    .name {
      font-size: 14px;
      font-weight: 600;
      color: $secondary-color;
      display: flex;
      align-items: center;
    }

    .setting {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 5px;

      span {
        font-size: 10px;
        font-weight: 400;
        margin-right: 8px;
        color: $secondary-color;
      }
    }
  }

  .dropdown {
    list-style-type: none;
    background: $background;
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    padding: 9px 13px;
    width: 100%;
    border-radius: 10px;
    position: absolute;
    left: 0;

    li {
      width: 100%;
    }

    a {
      text-decoration: none;
      font-size: 14px;
      line-height: 21px;
      color: $primary-color;
      font-weight: 600;
      width: 100%;
    }
  }
}

.btn {
  width: 100%;
  border-radius: 10px;
  height: 50px;
  padding: 14px 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: $white;
  background: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;

  &.green {
    background: #0B8B00;

  }

  &.white {
    color: $primary-color;
    background: $background;
    box-shadow: $shadow;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

#dashboard {
  padding: 19px 20px 24px 26px;

  .top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;

    .btn {
      width: 115px;
    }
  }

  .categoriesChartContainer, .salesChartContainer {
    width: 35%;
    border-radius: 10px;
    background: $background;
    box-shadow: $shadow;
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    padding: 20px 47px 26px 44px;
    height: 511px;

  }

  .salesChartContainer {
    width: 45%;
  }

  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .shortRowContainer {
      width: 66%;
    }

    .companiesChartContainer {
      width: 32.8%;
      margin-top: 15px;
    }
  }

  .shortRow {
    width: 100%;

    display: flex;
    justify-content: space-between;
    margin-top: 15px;

  }

  .shortRowWrap {
    flex-wrap: wrap;
    margin-top: 25px;

    .columnChart {
      width: 24%;
      margin-bottom: 30px;
    }

    .noData {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .shortRound {
    display: flex;
    height: 450px;
    width: 31%;
    justify-content: space-around;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;

    .tableHeader {
      margin-top: 10px;
      margin-bottom: -44px;
    }

    .chartRound {
      position: relative;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 200px;
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
    }
  }
}

.salesChart {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chartTableHeader {
      margin-bottom: 0;
      width: 50%;
    }

    .legendContainer {
      display: flex;
    }

    .legend {
      display: flex;
      align-items: center;
      margin-left: 40px;

      div {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background: #FF5F00CF;
        margin-right: 11px;

      }

      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: #FF5F00CF;
      }

      &.sales {
        div {
          background: #FF59D1;
        }

        p {
          color: #FF59D1;
        }
      }
    }
  }
}

.chartTableHeader {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: $secondary-color;
  margin-bottom: 24px;

  .react-datepicker {
    display: flex;
  }

  .select {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      color: $primary-color;
    }

    svg {
      margin-left: 7px;
      width: 7px;
      height: 4px;

      path {
        fill: $primary-color;
      }
    }
  }
}

.chartTable {
  width: 100%;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid #A6B4C8B2;

  }

  thead {
    tr {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: $secondary-color;
      text-align: left;

      th {
        padding: 0 5px 6px 0;

      }
    }
  }

  .category, .products {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }


  .sales, .amount, .number, .qty {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
  }


  .status {
    text-align: right;

  }

  tbody {
    tr {
      height: 60px;
    }

    td {
      padding: 11px 5px 5px 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: $secondary-color;


      &.amount {
        color: $color-orange;
      }

      &.status {

        padding: 3.5px 5px 3.5px 3px;

        > div {
          border-radius: 50px;
          height: 30px;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
          border: 1px solid $color-orange;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 83px;
          margin-left: auto;
        }
      }
    }
  }
}

.chartTableContainer {
  padding: 20px 29px 23px 34px;
  border-radius: 10px;
  background: $background;
  border: 0.5px solid;
  border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
  box-shadow: $shadow;
}


.seeAll {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #0B8B00;
  margin-top: 11px;
}

.topSoldProducts {
  width: 23%;
}

#addHealthplan {
  padding: 48px 124px;

  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .inputsBlock {
    display: flex;

    .col {
      width: 386px;
      margin-right: 20px;
    }
  }

  .selectsBlock {
    width: 788px;

    .otcSelect {
      margin-bottom: 20px;
    }
  }

  .actions {
    margin-top: 30px;

    .btn {
      width: 386px;
      height: 70px;
    }
  }
}

.otcInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  position: relative;


  input {
    width: 100%;
    height: 44px;
    border-radius: 10px;
    background: $background;
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: 10px 10px 20px 0 #A6B4C8B2 inset, -12px -12px 20px 0px #FFFFFFCC inset;
    outline: none;
    padding: 18px 22px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $secondary-color;
    font-family: inherit;

    &::placeholder {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: rgba($secondary-color, 0.5);
    }

  }

  &.password {
    input {
      padding: 18px 28px;
    }
  }

  .error {
    font-size: 12px;
    font-weight: 500;
    color: #EB001B;


  }

  &.hasError {
    label {
      &:before,
      &:after {
        bottom: 28px;
      }
    }
  }

  svg {
    position: absolute;
    right: 15px;
    top: 44px;
  }

  label {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $secondary-color;
    margin-bottom: 5px;

    &:before,
    &:after {
      top: 31px;
      display: block;
      position: absolute;
      font-size: 18px;
      line-height: 30px;
      font-weight: 500;
      color: #15274B;
      padding: 5px;
      bottom: 11px;
    }

    &[data-append] {
      + input {
        padding-right: 30px;
      }

      &:after {
        content: attr(data-append);
        right: 11px;
        font-size: 16px;
      }
    }

    &[data-prepend] {
      + input {
        padding-left: 30px;
      }

      &:before {
        content: attr(data-prepend);
        left: 5px;
      }
    }
  }

  .icon {
    position: absolute;
    left: 10px;
    top: 14px;

    &.right {
      left: unset;
      right: 10px;
      cursor: pointer;

      &.password {
        path {
          fill: #bbc0cd;
        }
      }
    }
  }
}

.otcSelect {
  .label {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $secondary-color;
    min-height: 24px;
  }

  .error {
    font-size: 12px;
    font-weight: 500;
    color: #EB001B;
  }

  .arrowIcon {
    margin: 0 22px 0 10px;

    path {
      fill: $secondary-color;
    }
  }

  .otc__control {
    min-height: 44px;
    border-radius: 10px;
    background: $background;
    box-shadow: $shadow;
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    margin-top: 12px;
    cursor: pointer;
    padding: 5px 16px;
  }

  .otc__input-container, .otc__value-container {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $secondary-color;
  }

  .otc__option {
    margin-bottom: 10px;
  }

  .otc__single-value {
    color: $secondary-color;

  }

  .otc__placeholder {
    color: $secondary-color;
    opacity: 0.6;
  }

  .otc__option--is-selected {
    background-color: $primary-color;
    color: $white !important;
  }

  .otc__multi-value {
    height: 36px;
    width: 127px;
    border-radius: 10px;
    background: $secondary-color;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    .otc__multi-value__label {
      color: $white;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }

    .otc__multi-value__remove {
      &:hover {
        background-color: $white;
        color: $secondary-color;
        border-radius: 10px;
      }
    }
  }

  .otc__menu {
    border-radius: 10px;

  }

  &.isMulti {
    .otc__menu-list {
      padding: 28px;

      > div {
        margin-bottom: 20px;
      }
    }
  }

  .otc__option {
    cursor: pointer;
    color: $secondary-color;
  }

  .otc__menu-list {
    max-height: 300px;
    overflow: auto;
    background: $background;
    box-shadow: $shadow;
    border-radius: 10px;


  }
}

.otcCheckbox {

  input {
    padding: 0;
    height: 100%;
    width: 100%;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: $secondary-color;
  }

  label:before {
    content: '';
    -webkit-appearance: none;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    padding: 10px;
    position: relative;
    display: inline-block;
    border-radius: 5px;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: 10px 5px 20px 0 #A6B4C880, -12px -12px 20px 0 #FFFFFFCC, 0 4px 4px 0 #C6CEDA;
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    left: 7px;
    width: 6px;
    height: 14px;
    border: solid #E6E9EF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    color: red;
  }

  input:checked + label:before {
    background: $primary-color;

  }
}

#patients, #orders, #settings, #products {

  padding: 20px 69px 20px 69px;

  .top {
    display: flex;
    justify-content: space-between;

    .otcInput {
      label:before {
        top: 34px;
      }
    }

    .btn {
      margin-top: 33px;
      height: 44px;
      width: 204px;

      svg {
        margin-right: 10px;

        path {
          fill: $white;

        }
      }
    }

    > div {
      display: flex;
      flex-wrap: wrap;
    }

    .otcInput {
      width: 210px;
      margin-right: 20px;

      label {
        color: $secondary-color;
        margin-bottom: 8px;
      }
    }

    .otcSelect {
      width: 200px;
      margin-right: 20px;

      .label {
        color: $secondary-color;
      }

      .otc__control {
        height: 44px;
        margin-top: 8px;
      }
    }

  }
}

.productsTable {
  .all {
    width: 6%;
    min-width: 6%;
    max-width: 6%;
  }

  .productName, .category {
    width: 24%;
    min-width: 24%;
    max-width: 24%;
  }

  .item, .price, {
    width: 13%;
    min-width: 13%;
    max-width: 13%;
  }

  .qty, .status {
    width: 12%;
    min-width: 12%;
    max-width: 12%;
  }

  .head {
    .all {
      display: flex;
    }
  }

  .productName, .category {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20%;
    margin-right: 8px;
  }

  .category {
    margin-right: 8px;
  }
}

#orders, #products {
  .top {
    .btn {
      height: 44px;
    }
  }
}

.datepicker {
  .error {
    font-size: 12px;
    font-weight: 500;
    color: #EB001B;
  }

}

.react-datepicker {
  border-radius: 10px !important;
  border: none !important;
  box-shadow: $shadow;

  .react-datepicker__day--selected {
    background-color: $primary-color;
    border: none;
    outline: none;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;

  }

  .react-datepicker__header {
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  }

}

.table {
  width: 100%;
  margin-top: 30px;

  .table {
    width: 100%;
    border-spacing: 0 10px;
  }

  .head {
    display: flex;
    align-items: center;
    height: 66px;
    border-radius: 10px;
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    padding: 0 43px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $secondary-color;

  }

  .body {
    .row {
      height: 62px;
      border: 0.5px solid;
      border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
      box-shadow: $shadow;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      border-radius: 10px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      padding: 0 43px;

      > div {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: $secondary-color;
      }

      .actions {
        height: 44px;
        width: 91px;
        border-radius: 10px;
        background: $background;
        box-shadow: $shadow;
        border: 0.5px solid;
        border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 13px;

        svg {
          cursor: pointer;
        }
      }

    }


  }
}

.patientsTable {
  .name, .date, .phone, .address {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
  }

}

.seeMore {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: $primary-color;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;

  svg {
    margin-left: 4px;

    path {
      fill: $primary-color;
    }
  }
}

#patientSingle {
  padding: 55px 57px 78px 57px;

  .balances {
    p {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: #15274B;
      margin-bottom: 5px;
    }

    .balance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      position: relative;


      .name {
        width: 45%;
        margin-bottom: 0;
      }

      .amount {
        width: 28%;
        margin-bottom: 0;

      }

      .addPlace {
        width: 40px;
      }

      .add {
        height: 40px;
        width: 40px;
        border-radius: 10px;
        box-shadow: $shadow;
        background: $background;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .addSvg {
        path {
          fill: #0B8B00;
        }
      }
    }
  }

  .otcSelect {
    .otc__control {
      height: 60px;
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }

  .h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .action {
      height: 62px;
      width: 67px;
      border-radius: 10px;
      background: $background;
      box-shadow: $shadow;
      border: 0.5px solid;
      border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }

  }

  form {
    display: flex;
    justify-content: space-between;

    .main {
      display: flex;
    }

    .account {
      margin-top: 62px;
      height: 159px;
      width: 252px;
      border-radius: 10px;
      background: $background;
      box-shadow: $shadow;
      padding: 22px 48px 44px 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > p {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: $primary-color;
        margin-bottom: 19px;

        svg {
          margin-right: 8px;
        }
      }
    }
  }

  .col {
    width: 386px;

    .btn {
      height: 70px;

    }

    &.left {
      margin-right: 76px;
    }

    .addressDetails {
      margin-top: 54px;

      > p {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color: $primary-color;
        margin-bottom: 14px;

      }

      .shortInputs {
        display: flex;
        justify-content: space-between;

        .otcInput {
          width: 183px;

        }
      }
    }
  }
}

.otcRadio {
  .radio {
    display: none;
  }

  .label {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .check {
    width: 21px;
    height: 21px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid $secondary-color;
    display: flex;
    position: relative;
  }

  .radio:checked {
    & + .label {
      .check:after {
        position: absolute;
        content: "";
        width: 13px;
        height: 13px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        display: flex;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 18%;
        background: $secondary-color;
      }
    }
  }

  .text {
    margin-left: 11px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: $secondary-color;
  }
}

.radioContainer {
  margin-bottom: 20px;

  > span {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $primary-color;
  }

  > div {
    display: flex;

    .otcRadio {
      margin-top: 14px;
      margin-right: 27px;
    }
  }
}


.ordersTable {
  .number, .qty, .price {
    width: 13%;
    min-width: 13%;
    max-width: 13%;
  }

  .qty, .price {
    text-align: center;
  }

  .productName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 15px;
  }

  .patient, .date, .productName {
    width: 17%;
    min-width: 17%;
    max-width: 17%;
  }

  .status {
    width: 14%;
    min-width: 14%;
    max-width: 14%;
  }

  .row {
    .price {
      color: $color-orange !important;
    }
  }

}

.loader {
  border: 5px solid white;
  border-radius: 50%;
  border-top: 5px solid #00758B;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.loaderContainer {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.settingsTabs {
  display: flex;

  .btn {
    width: 133px;

    &.second {
      width: 159px;
      margin-left: 8px;
    }
  }
}

#patients {
  .top {
    .btn {
      &.add {
        margin-right: 10px;
      }

      &.green {
        width: unset;
        padding: 14px;
      }
    }

  }

  .row {
    cursor: pointer;
  }

  .fileInputButton {
    height: 55px;
    margin-top: 33px;
    margin-bottom: unset;

    .file {
      height: 100%;
      box-shadow: unset;
      padding-top: 18px;

      p {
        display: flex;

        svg {
          margin-right: 10px;

          path {
            fill: white;
          }
        }
      }
    }
  }
}

.react-datepicker__year-dropdown {
  max-height: 250px;
  overflow-y: scroll;
}


.child {
  .otcCheckbox {
    margin: 10px 20px;
  }
}

.customMenu {
  height: 350px;
  overflow-y: scroll;
  background: $background;
  box-shadow: $shadow;
  padding: 15px;

}

.partlySelected {
  .otcCheckbox {
    label {
      &:before {
        content: "";
        background-image: url("../icons/minus.svg");
        background-repeat: no-repeat;
        background-size: 15px 10px;
        background-position: center;
      }
    }
  }

}

.noData {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #15274B;
  margin-top: 30px;
}


.barChartRow {
  display: flex;
  margin-bottom: 23px;
  align-items: center;
  width: 100%;

  .title {
    min-width: 100px;
    margin-right: 25px;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: $secondary-color;
  }

  .titleUi {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 99px !important;
    overflow: hidden;
  }

  .percentage {
    width: 10%;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: $secondary-color;
  }

  .progressContainer {
    position: relative;
    margin-right: 13px;
    width: 80%;
    border-radius: 50px;
    overflow: hidden;

    .progress {
      height: 32px;
      width: 100%;
      border-radius: 50px;
      box-shadow: 0 4px 4px 0 #A6B4C8BF inset;
    }

    .progressFill {
      background: #2877EE;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50px;
      width: 0;
      transition: width 500ms;
    }
  }
}

.membersBySales {
  width: 18%;
  height: 511px;

  .chartTable {
    tbody tr {
      &:last-child {
        border: unset;
      }
    }

    .index {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      color: $secondary-color;
      width: 15%;
      min-width: 15%;
      max-width: 15%;
    }

    .memberInfo {
      width: 80%;
      min-width: 80%;
      max-width: 80%;

      > div {
        display: flex;

        .imageBlock {
          margin-right: 10px;
          display: flex;
          align-items: center;
          width: 40px;
          height: 40px;

          img {
            width: 100%;
            border-radius: 50%;
            object-fit: contain;
            padding: 8px 0;
            margin-right: 15px;
          }
        }

        .name {
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          color: $secondary-color;
        }

        .sickness {
          font-size: 12px;
          font-weight: 400;
          line-height: 30px;
          color: $secondary-color;

        }
      }
    }
  }

}

.columnChart {
  width: 25%;
  height: 450px;
  border-radius: 10px;
  background: $background;
  box-shadow: $shadow;
  padding: 26px 22px;

  &:last-child {
    width: 18%;
  }

  .chartTableHeader {
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
    }
  }

  .pink {
    svg {
      margin-right: 10px;
    }
  }
}

.otcSwitcher {
  display: flex;
  align-items: center;

  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: $secondary-color;
    margin-right: 12px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 53px;
    height: 30px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;

  }

  .slider:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;

  }

  input:checked + .slider {
    background-color: $primary-color;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $primary-color;
  }

  input:checked + .slider:before {
    transform: translateX(23px);
  }
}

.notifications {
  max-height: 380px;
  width: 434px;
  border-radius: 10px;
  box-shadow: $shadow;
  background: $background;
  position: absolute;
  top: 50px;
  right: 10px;
  padding: 19px 20px 17px 29px;
  overflow-y: auto;
  overflow-x: hidden;

  .seeMore {
    justify-content: flex-start;
    margin-top: 6px;

    svg {
      display: none;
    }
  }

  .notificationItem {
    width: 383px;
    border-radius: 10px;
    padding: 13px 16px;
    background: $background;
    box-shadow: $shadow;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .dot {
      width: 12px;
      height: 12px;
      background: #5D5FEF;
      border-radius: 50%;
      margin-right: 10px;
    }

    .info {
      .content {
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        font-weight: 400;
        color: $secondary-color;

        b {
          font-weight: 600;
        }
      }

      .date {
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        color: $secondary-color;
      }
    }
  }

  .noNotifications {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: $secondary-color;
  }

  .loaderContainer {
    height: unset;
  }

  .top {
    display: flex;
    margin-bottom: 13px;

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: $secondary-color;
      width: 100%;
      justify-content: space-between;

    }

    svg {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
}

#chart_dropdown {
  position: relative;

  .dropdown_two {
    list-style-type: none;
    background: $background;
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    padding: 9px 13px;
    width: 150px;
    border-radius: 10px;
    position: absolute;
    z-index: 5;
    right: 0;
    top: 25px;

    li {
      width: 100%;
      height: 30px;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}


.custom_input_datePicker_wrapper {
  position: relative;

  .custom_input_datePicker {
    outline: none;
    border: none;
    background: transparent;
    width: 160px;
    color: #2877EE;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }

  .remove {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 14px !important;
    cursor: pointer;
  }
}


.tooltip {
  width: 50px;
  z-index: 9999;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  visibility: hidden;
  padding: 5px;
  border-radius: 5px;
}

.tooltip-arrow {
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent;
}

#dashboard .dashboardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -0.5%;
  width: 101%;

  .categoriesChartContainer {
    width: 38%;
    margin: 0.5%;
    min-width: 483px;
  }

  .salesChartContainer {
    width: 38%;
    margin: 0.5%;
    min-width: 483px;
  }

  .membersBySales {
    width: 21%;
    margin: 0.5%;
    min-width: 267px;
  }

  .topSoldProducts {
    width: 25%;
    margin: 0.5%;
    min-width: 318px;
  }

  .shortRound {
    width: 25%;
    margin: 0.5%;
    min-width: 318px;
  }

  .columnChart.columnChart-0 {
    width: 25%;
    margin: 0.5%;
    min-width: 318px;
  }

  .columnChart.columnChart-1 {
    width: 21%;
    margin: 0.5%;
    min-width: 267px;
  }

  .columnChart {
    width: 24%;
    margin: 0.5%;
    min-width: 306px
  }
}

@media screen and (max-width: 1570px) {
  #dashboard .dashboardContainer {
    .categoriesChartContainer {
      width: 49%;
      min-width: 450px;
    }

    .salesChartContainer {
      width: 49%;
      min-width: 450px;
    }

    .membersBySales {
      width: 49%;
    }

    .topSoldProducts {
      width: 49%;
    }

    .shortRound {
      width: 30%;
      min-width: 267px;
    }

    .columnChart.columnChart-0 {
      width: 37%;
    }

    .columnChart.columnChart-1 {
      width: 30%;
    }

    .columnChart {
      width: 32.333%;
    }
  }
}

@media screen and (max-width: 1200px) {
  #dashboard .dashboardContainer {
    .categoriesChartContainer,
    .salesChartContainer,
    .membersBySales,
    .topSoldProducts,
    .shortRound,
    .columnChart.columnChart-0,
    .columnChart.columnChart-1,
    .columnChart {
      width: 49%;
      min-width: 300px;
    }
  }
}

@media screen and (max-width: 940px) {
  #dashboard .dashboardContainer {
    .categoriesChartContainer,
    .salesChartContainer,
    .membersBySales,
    .topSoldProducts,
    .shortRound,
    .columnChart.columnChart-0,
    .columnChart.columnChart-1,
    .columnChart {
      width: 100%;
    }
  }
}